import axios from "axios";

const state = () => ({
  users: [],
 
});
const getters = {
  usersState(state) {
    return state.users;
  },
 
};
const mutations = {
  getUserLoad(state, payload) {
    state.users = payload;
    console.log("del user",state.users)
  },
  
};
const actions = {
  getUsers(context) {
    axios
      .get(process.env.VUE_APP_BASE_URL+"usuarios")
      .then((data) => {
         console.log("usuarios",data.data) 
        context.commit("getUserLoad", data.data);
      });
  },
 
};
export default {
  state,
  getters,
  actions,
  mutations,
};
