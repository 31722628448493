<template>
  <div>
    <v-list-item>
      <v-list-item-avatar>
        <v-icon :class="data.color" dark>{{ "mdi-" + data.icon }}</v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{ data.title }}</v-list-item-title>
      </v-list-item-content>

      <v-list-item-action>
        <v-list-item-subtitle style="color: green"
          >+$ {{ formattedValue }}</v-list-item-subtitle
        >
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: "dataListInfoComponent.vue",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    formattedValue() {
      // Convertimos el string a número
      const numericValue = parseFloat(this.data.value);

      // Verificamos si el valor es un número válido
      if (!isNaN(numericValue)) {
        // Opciones para personalizar el formato
        const options = {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          useGrouping: true, // Habilita el uso de separadores de miles (puntos o comas)
        };

        // Formateamos el número con separadores de miles (comas) usando toLocaleString()
        return numericValue.toLocaleString("es-ES", options);
      } else {
        return "Valor no válido";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
