<template>
  <div>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="isPasswordDialog"
      persistent
    >
      <v-card>
        <v-app-bar color="indigo" dark>
          <v-toolbar-title>Cambiar Contraseña</v-toolbar-title>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              text
              @click="
                () => {
                  this.$refs.resetPass.reset();

                  this.$emit('cerrar-cambio-pass', false);
                }
              "
              >Cerrar</v-btn
            >
          </div>
        </v-app-bar>
        <div>
          <v-form ref="resetPass">
            <v-card-text class="pa-8">
              <v-text-field
                v-for="(input, i) in inputsPass"
                :key="i"
                v-model="input.value"
                :type="inputType(i)"
                :label="input.label"
                :append-icon="viewPassIcon(i)"
                @click:append="togglePasswordVisibility(i)"
                required
                outlined
                :rules="i === 1 ? repeatPasswordRules : []"
              ></v-text-field>
            </v-card-text>
          </v-form>

          <v-card-actions class="pt-0 mt-0">
            <v-btn
              block
              color="success"
              :loading="loader"
              :disabled="isSubmitDisabled"
              @click="enviarPass()"
              >confimar cambio</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DialogoCambioPass",
  props: {
    isPasswordDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loader: false,
      ruta: "auth/resetpass",
      repeatPasswordRules: [
        (value) =>
          value === this.inputsPass[0].value || "Las contraseñas no coinciden",
      ],

      viewPass: [false, false], // Track password visibility for each input
      inputsPass: [
        {
          value: null,
          label: "Nueva Contraseña",
        },
        {
          value: null,
          label: "Repetir Contraseña",
        },
      ],
    };
  },
  methods: {
    async enviarPass() {
      if (this.$refs.resetPass.validate()) {
        let dataUser = JSON.parse(localStorage.getItem("token"));
        let data = {
          password: this.inputsPass[1].value,
          email: dataUser.email,
        };

        try {
          this.loader = true;
          let res = await axios.post(
            process.env.VUE_APP_BASE_URL + this.ruta,
            data
          );
          console.log(">>>>>", res.data);
          this.$toast.open({
            message: res.data.msg,
            position: "top-right",
            type: res.data.type,
            duration: 4000,
          });
        } catch (e) {
          console.log(e);
          this.$toast.open({
            message: "Error comunicarse con soporte ",
            position: "top-right",
            type: "error",
            duration: 4000,
          });
        } finally {
          this.loader = false;
          this.$refs.resetPass.reset();
          this.$emit("cerrar-cambio-pass", false);
        }
      } else {
        console.log("no son iguales");
        this.$toast.open({
          message: "No  coinciden las contraseñas ",
          position: "top-right",
          type: "error",
          duration: 3000,
        });
      }
    },

    togglePasswordVisibility(index) {
      this.$set(this.viewPass, index, !this.viewPass[index]);
    },
  },
  computed: {
    isSubmitDisabled() {
      const pass1 = this.inputsPass[0].value;
      const pass2 = this.inputsPass[1].value;

      return pass1 === null || (pass1 === "" && pass2 === null) || pass2 === "";
    },
    inputType() {
      return (index) => (this.viewPass[index] ? "text" : "password");
    },
    viewPassIcon() {
      return (index) => (this.viewPass[index] ? "mdi-eye-off" : "mdi-eye");
    },
  },
};
</script>

<style lang="scss" scoped></style>
