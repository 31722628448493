import axios from "axios";


const state = () => ({
  marcas: [],
  loder:true
  
});
const getters = {
  marcas(state) {
    return state.marcas;
  },
  loder(state){
    return state.loder
  }
 
};
const mutations = {
  setData(state,payload){
    return new Promise(resolve=>{     
       state[payload.key]=payload.value
       state.loder = false;

      resolve()
    })
  },
    
};
const actions = {
    getMarcas({commit}) {
        return new Promise(async (resolve)=>{
          try{
            let r = await axios.get(process.env.VUE_APP_BASE_URL+"marcas")
                 console.log("desde el store las marcas",r.data);
                
                 commit("setData", {key:"marcas",value:r.data});
                 resolve()
          }catch(e){
            console.log(e);

          }
            // .then((data) => {
            //   console.log(">>>>>data",data)
            //   commit("setMarcas", data.data);
            //   resolve()
            // }).catch((err)=> console.log(err));
        })
        
    
  },
  
  
};
export default {
  state,
  getters,
  actions,
  mutations,
};
