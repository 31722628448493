import axios from "axios";

const state = () => ({
  singLoderPedidos: false,
});

const mutations = {};
const actions = {
  getPedidos({ commit, rootState }, refresh) {
    return new Promise(async (resolve) => {
      try {
        rootState.pedidos = [];
        let resp = await axios.get(process.env.VUE_APP_BASE_URL + "pedidos");

        commit(
          "setDatos",
          {
            key: "pedidos",
            value: resp.data,
          },
          { root: true }
        );
        resolve();
      } catch (e) {
        console.log(e);
      }
    });
  },
  getPedidosEnProceso({ commit, rootState }, refresh) {
    return new Promise(async (resolve) => {
      try {
        rootState.pedidos = [];
        let resp = await axios.get(
          process.env.VUE_APP_BASE_URL + "pedidoswhere?id_estatus_pedido=3"
        );

        commit(
          "setDatos",
          {
            key: "pedidosEnProceso",
            value: resp.data,
          },
          { root: true }
        );
        resolve();
      } catch (e) {
        console.log(e);
      }
    });
  },
};
export default {
  state,

  actions,
  mutations,
};
