<template>
  <div>
    <v-container>
        <v-row class="d-flex justify-center aling-center">
      <v-col cols="12" md="4" class="d-flex justify-center aling-center">
        <lottie :options="defaultOptions" />
      </v-col>
      <v-col cols="12" md="6" class="d-flex align-items-center">
        <span>
            <h1 style="color: #333; font-size: 2em">Acceso Denegado</h1>
           <br/>
        <p style="color: #999; font-size: 1.5em">
          Error 403: No tienes permiso para acceder a esta página
        </p>
        </span>
        
      </v-col>
    </v-row>
    </v-container>
  </div>
</template>

<script>
import * as animationData from "../assets/lottie/403.json";
export default {
  data() {
    return {
      defaultOptions: {
        animationData: animationData.default,
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
