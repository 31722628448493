<template>
    <div>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <lottie :width=500 :options="defaultOptions" />
                </v-col>
            </v-row>
        </v-container>

    </div>
</template>

<script>
import * as animationData from "../assets/lottie/error.json";

    export default {
        data() {
            return {
                defaultOptions: {
                 animationData: animationData.default,
      },
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>