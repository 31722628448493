<template>
  <div>
    <v-card>
      <v-date-picker
        v-model="dates"
        locale="ARG"
        full-width
        range
      ></v-date-picker>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "DownloadExcel",
  data() {
    return {
      dates: [],
      dateExcel: [],
    };
  },

  methods: {
    validDataExcel([startDate, endDate]) {
      const date1 = new Date(startDate);
      const date2 = endDate ? new Date(endDate) : undefined;
      if (
        date2 === undefined ||
        date1 < date2 ||
        date1.getTime() === date2.getTime()
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    dates(v) {
      const [startDate, endDate] = v;
      if (this.validDataExcel(v)) {
        this.$emit(
          "dataExcel",
          (this.dateExcel = { desde: startDate, hasta: endDate })
        );
      } else {
        this.$emit(
          "dataExcel",
          (this.dateExcel = { desde: endDate, hasta: startDate })
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
