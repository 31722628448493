<template>
  <div>
    <template>
      <v-expansion-panels class="rounded-lg mb-4">
        <v-expansion-panel>
          <v-expansion-panel-header v-slot="{ open }">
            <v-row no-gutters>
              <v-col cols="12" md="4"> Filtros de Fecha </v-col>
              <v-col cols="8" class="text--secondary">
                <v-fade-transition leave-absolute>
                  <span v-if="open">Selecciona una Fecha</span>
                  <v-row v-else no-gutters style="width: 100%">
                    <v-col cols="12" md="6">
                      Desde: {{ formattedDesde || "Sin Datos" }}
                    </v-col>
                    <v-col cols="12" md="6">
                      Hasta: {{ formattedHasta || "Sin Datos" }}
                    </v-col>
                  </v-row>
                </v-fade-transition>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row justify="center" no-gutters>
              <v-col cols="12" md="4">
                <DownloadExcel @dataExcel="setDataExcel" />
                <v-btn
                  color="primary"
                  class="mt-5"
                  @click="sendData"
                  :loading="loder"
                  block
                  >consultar</v-btn
                >
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </div>
</template>

<script>
import DownloadExcel from "@/components/DownloadExcel.vue";
import DataPanelService from "@/services/DataPanelService.js";

export default {
  name: "DataCalendarDashboard",
  components: {
    DownloadExcel,
  },
  data() {
    return {
      open: false,
      showCalendar: false,
      dataExcel: null,
      loder: false,
    };
  },
  computed: {
    formattedDesde() {
      return this.formatDate(this.dataExcel?.desde);
    },
    formattedHasta() {
      return this.formatDate(this.dataExcel?.hasta);
    },
  },
  methods: {
    formatDate(fecha) {
      if (fecha) {
        const partes = fecha.split("-");
        const nuevaFecha = `${partes[2]}-${partes[1]}-${partes[0]}`;
        return nuevaFecha;
      } else {
        return null;
      }
    },
    setDataExcel(v) {
      this.dataExcel = v;
      console.log(this.dataExcel);
    },
    sendData() {
      if (this.dataExcel) {
        this.loder = true;
        DataPanelService.getDataParameter(this.dataExcel)
          .then((resp) => {
            this.$emit("dataCards", resp.cards);
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            this.loder = false;
            this.open = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card-text {
  line-height: 1.25rem;
  padding: 24px 0 24px 0;
}
.variant--blue--card {
  background-color: #26356c;
}
</style>
