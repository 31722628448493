import axios from "axios";

const state = () => ({
  productos: [],
  catalogosBancos:[],
  bancos:[],
  tiposTarjetas:[],
  loderProducts: false,
});
const getters = {
  tiposTarjetasState(state) {
    return state.tiposTarjetas;
  },
  bancosState(state) {
    return state.bancos;
  },
  productosState(state) {
    return state.productos;
  },
  loderTableProductsState(state) {
    return state.loderProducts;
  },
};
const mutations = {
  getProductosLoad(state, payload) {
    state.productos = payload;
    state.loderProducts = false;
  },
  getCatalogosLoad(state, payload) {
    state.catalogosBancos = payload;
    state.bancos= payload.bancos
    state.tiposTarjetas= payload.mediosPago
    console.log('los bancos',state.bancos);
    console.log('los mediosPagos',state.tiposTarjetas);
    
   
  },
};
const actions = {
  getProductos({ commit, state }, refresh) {
    return new Promise(async (resolve) => {
      try {
       
        if (state.productos.length !== 0 && !refresh) {
          console.log("entro en el return de productos");
          resolve();
          return;
        }
        state.productos = [];
        state.loderProducts = true;
        console.log('>>>>>',state.productos);
        let resp = await axios.get(process.env.VUE_APP_BASE_URL + "products");
        console.log("entro en el get productos");
        commit("getProductosLoad", resp.data);
        resolve();
      } catch (e) {
        console.log("erroorrrr",e);
      }
    });
  },
  getCatalogosBancos({ commit, state }, refresh) {
    return new Promise(async (resolve) => {
      try {
       
        if (state.catalogosBancos.length !== 0 && !refresh) {
          console.log("entro en el return de productos");
          resolve();
          return;
        }
        state.catalogosBancos = [];
        
        console.log('>>>>>',state.catalogosBancos);
        let resp = await axios.get(process.env.VUE_APP_BASE_URL + "cajas/catalogos");
        console.log("entro en el get catalogos",resp.data);
        commit("getCatalogosLoad", resp.data);
        resolve();
      } catch (e) {
        console.log("erroorrrr",e);
      }
    });
  }
};
export default {
  state,
  getters,
  actions,
  mutations,
};
