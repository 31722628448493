const state = () => ({
  carrito: [],
  activarModuloPago: false,
  mediosPagos: [],
});
const getters = {
  carritoState(state) {
    return state.carrito;
  },
  mediosPagosState(state) {
    return state.mediosPagos;
  },
  ModuloPagoState(state) {
    return state.activarModuloPago;
  },
  totalCarrito(state) {
    // Calcula el total del carrito sumando el precio por unidades de cada producto
    return state.carrito.reduce((total, item) => {
      return total + item.precio_unitario * item.unidades;
    }, 0);
  },
};
const mutations = {
  activarPago(state, payload) {
    state.activarModuloPago = payload;
    console.log("desde la mutacion", state.activarModuloPago);
  },
  deleteIdCarrito(state, payload) {
    // Buscar el índice del producto en el carrito
    console.log("carrito", state.carrito);
    console.log("payload");

    const index = state.carrito.findIndex(
      (item) => item.id_producto === payload.id
    );
    console.log(index);

    if (index !== -1) {
      // Eliminar el producto del carrito
      state.carrito.splice(index, 1);
    }

    // Mostrar en consola el estado del carrito
    console.log(
      "Producto eliminado. Estado actual del carrito:",
      state.carrito
    );
  },
  addCarrito(state, payload) {
    // Asegurarte de que el campo recibido tenga unidades numéricas
    const campo = {
      ...payload,
      unidades: Number(payload.unidades),
    };

    // Buscar el índice del producto existente en el carrito
    const existingItemIndex = state.carrito.findIndex(
      (item) => item.id_producto === campo.id_producto
    );
    


    if (existingItemIndex !== -1 ) {
      console.log("entra el add", state.carrito[existingItemIndex].unidades);
      // Sumar las unidades al producto existente
      state.carrito[existingItemIndex].unidades += Number(campo.unidades);
    } else {
      // Añadir un nuevo producto al carrito
      state.carrito.push(campo);
    }

    // Mostrar en consola el estado del carrito
    console.log("el producto desde el store mutacion", state.carrito);
  },
  addPago(state, payload) {
    state.mediosPagos.push(payload);
    console.log(state.mediosPagos);
  },
  finalizarVenta(state) {
    (state.carrito = []),
      (state.activarModuloPago = false),
      (state.mediosPagos = []);
  },
};
const actions = {
  handleCarrito({ commit }, producto) {
    if (producto.eliminar) {
      commit("deleteIdCarrito", producto);
    } else {
      commit("addCarrito", producto);
    }
  },
  handlePago({ commit }, payload) {
    commit("activarPago", payload);
  },
  handleMedioPago({ commit }, payload) {
    commit("addPago", payload);
  },
  handleFinalizarVenta({ commit }) {
    commit("finalizarVenta");
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
