<template>
  <div class="d-flex align-center gap-3">
    <div class="text-center">
      <v-avatar rounded :color="infoCard.color">
        <v-icon dark> {{ "mdi-" + infoCard.icon }} </v-icon>
      </v-avatar>
    </div>
    <div class="d-flex flex-column">
      <div class="title-card-info">{{ infoCard.title }}</div>
      <strong class="subtitle-card-info">{{ formattedValue }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  name: "cardDataInfoComponent",
  props: {
    infoCard: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    formattedValue() {
      // Convertimos el string a número
      const numericValue = parseFloat(this.infoCard.value);

      // Verificamos si el valor es un número válido
      if (!isNaN(numericValue)) {
        // Opciones para personalizar el formato
        const options = {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          useGrouping: true, // Habilita el uso de separadores de miles (puntos o comas)
        };

        // Formateamos el número con separadores de miles (comas) usando toLocaleString()
        let numberFinal = numericValue.toLocaleString("es-ES", options);
        console.log("cccc", this.infoCard);
        if (
          this.infoCard.title == "Pedidos Entregados" ||
          this.infoCard.title == "Pedidos Saldados" ||
          this.infoCard.title == "Pedidos Adeudados"
        ) {
          return parseInt(numberFinal);
        } else {
          return "$ " + numberFinal;
        }
      } else {
        return "Valor no válido";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.gap-3 {
  gap: 20px;
}
.title-card-info {
  font-size: 0.9rem;
  color: grey;
}
.subtitle-card-info {
  color: #424242;
  font-size: 1.3rem;
  font-weight: 500;
}
</style>
