import Vue from "vue";
import Vuex from "vuex";
/* import router from "../router"; */
import axios from "axios";
import user from "./modules/user";
import users from "./modules/users";
import productos from "./modules/productos";
import marcas from "./modules/marcas";
import pos from "./modules/pos";
import pedidosModule from "./modules/pedidosModule";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    categorias: [],
    subcategorias: [],
    proveedores: [],
    clientes: [],
    loderClientes: true,
    loderPagos: true,
    loderProveedores: true,
    pedidos: [],
    pedidosEnProceso:[],
    pagos: [],
  },
  getters: {
    categorias(state) {
      return state.categorias;
    },
    subcategorias(state) {
      return state.subcategorias;
    },
    proveedores(state) {
      return state.proveedores;
    },
    clientes(state) {
      return state.clientes;
    },
    loderClientes(state) {
      return state.loderClientes;
    },
    loderProveedores(state) {
      return state.loderProveedores;
    },
    loderPagos(state) {
      return state.loderPagos;
    },
    pedidos(state) {
      return state.pedidos;
    },
    pedidosEnProceso(state) {
      return state.pedidosEnProceso;
    },
    pagos(state) {
      return state.pagos;
    },
  },
  mutations: {
    actulizarTable(state, payload) {
      state[payload[1]][payload[1]].push(payload[0]);
    },
    actulizarTableEdit(state, payload) {
      console.log("el payload a editar", payload);
      const indice = state.users.users.findIndex((elemento, indice) => {
        if (elemento.id_usuario === payload.data.usuario.id_usuario) {
          return true;
        }
      });
      state.users.users[indice] = payload.data.usuario;
      /* console.log("usuario editado",state.users.users[indice])
      console.log("lista final",state.users) */
    },
    actulizarTableDelete(state, payload) {
      console.log("indice de delte", payload);
      const indice = state.users.users.findIndex((elemento, indice) => {
        if (elemento.id_usuario == payload) {
          return true;
        }
      });

      state.users.users.splice(indice, 1);
      console.log("delete>>>>", indice);
    },
    setTableDeleteNew(state, { id, table_name, table_field }) {
      return new Promise(async (resolve) => {
        try {
          if (state[table_name][table_name]) {
            state[table_name][table_name] = await state[table_name][
              table_name
            ].filter((elem) => elem[table_field] != id);
            console.log("entro el if");
          } else {
            state[table_name] = await state[table_name].filter(
              (elem) => elem[table_field] != id
            );
          }
          resolve();
        } catch (e) {
          console.log("error delete", e);
        }
      });
    },
    setDatos(state, payload) {
      return new Promise((resolve) => {
        state.loderClientes = false;
        state.loderProveedores = false;
        state.loderPagos = false;
        state[payload.key] = payload.value;
        console.log(payload);
        resolve();
      });
    },
  },
  actions: {
    actulizarData(context, payload) {
      context.commit("actulizarTable", payload);
    },
    actulizarDataEdit(context, payload) {
      context.commit("actulizarTableEdit", payload);
    },
    actulizarDataDelete(context, payload) {
      context.commit("actulizarTableDelete", payload);
    },
    actulizarDataDeleteNew({ commit }, payload) {
      commit("setTableDeleteNew", payload);
    },
    getCategorias({ commit }) {
      return new Promise(async (resolve) => {
        try {
          let r = await axios.get(process.env.VUE_APP_BASE_URL + "categorias");
          console.log(r.data);

          commit("setDatos", {
            key: "categorias",
            value: r.data,
          });
          resolve();
        } catch (e) {
          console.log(e);
        }
      });
    },
    getSubCategorias({ commit }) {
      return new Promise(async (resolve) => {
        try {
          let r = await axios.get(
            process.env.VUE_APP_BASE_URL + "subcategorias"
          );
          console.log(r.data);
          commit("setDatos", {
            key: "subcategorias",
            value: r.data,
          });
          resolve();
        } catch (e) {
          console.log(e);
        }
      });
    },
    getProveedores({ commit }) {
      return new Promise(async (resolve) => {
        try {
          let r = await axios.get(process.env.VUE_APP_BASE_URL + "proveedores");
          commit("setDatos", {
            key: "proveedores",
            value: r.data,
          });
          resolve();
        } catch (e) {
          console.log(e);
        }
      });
    },
    getClientes({ commit, state }, refresh) {
      return new Promise(async (resolve) => {
        try {
          if (state.clientes.length !== 0 && !refresh) {
            resolve();
            console.log("entro en el return de clientes");
            return;
          }
          state.loderClientes = true;
          state.clientes = [];
          let r = await axios.get(process.env.VUE_APP_BASE_URL + "clientes");
          console.log("entro la get clientes");
          commit("setDatos", {
            key: "clientes",
            value: r.data,
          });
          resolve();
        } catch (e) {
          console.log(e);
        }
      });
    },
    
    getPagos({ commit }) {
      return new Promise(async (resolve) => {
        try {
          let r = await axios.get(process.env.VUE_APP_BASE_URL + "pagos");
          console.log("pagos", r.data);

          commit("setDatos", {
            key: "pagos",
            value: r.data,
          });
          resolve();
        } catch (e) {
          console.log(e);
        }
      });
    },
  },
  modules: {
    user,
    users,
    productos,
    marcas,
    pedidosModule,pos
  },
});
